import './App.css';
import Home from './screens/home/Home';
import PrivacyPolicy from './screens/privacy/Privacy';
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacy" element={<PrivacyPolicy />} /> {/* Define route for /privacy */}
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
