import React from 'react';
import './Privacy.css';

const PrivacyPolicy = () => {
  return (
    <div style={{ padding: '20px', marginBottom: '100px'}}>
      <h1>MEMSWIPE PRIVACY POLICY</h1>
      <p>Last updated: 3/4/2024</p>

      <p>Memswipe ("us", "we", or "our") operates the Memswipe mobile application (hereinafter referred to as the "Service").</p>

      <p>This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service. Given our commitment to your privacy, our Service is designed to operate locally on your device and does not collect, use, or share any personal data.</p>

      <h2>1. Information Collection And Use</h2>
      <p>We do not collect any personal data with our Service. The Service operates entirely locally on your device, and no information is sent to or stored on any servers owned or operated by Memswipe or third parties.</p>

      <h2>2. Types of Data Not Collected</h2>
      <p>Given the local nature of the Service, we do not collect data of any kind, including but not limited to:</p>
      <ul>
        <li>Personal Data such as email address, phone number, etc.</li>
        <li>Usage Data including information on how the Service is accessed or used.</li>
        <li>Tracking & Cookies Data.</li>
      </ul>

      <h2>3. Use of Data</h2>
      <p>Since no data is collected, there is no use of data within the scope of our Service.</p>

      <h2>4. Transfer Of Data</h2>
      <p>Your data, including Personal Data, is not collected, stored, or transferred by us in any form as the Service functions completely locally on your device.</p>

      <h2>5. Disclosure Of Data</h2>
      <p>Legal Requirements: As we do not collect any personal data, there is no data to disclose in response to legal requirements or any other situation.</p>

      <h2>6. Security of Data</h2>
      <p>The security of your data is not applicable to our Service since we do not collect, store, or transfer any personal data. Your data remains solely on your device and under your control.</p>

      <h2>7. Changes to This Privacy Policy</h2>
      <p>We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately after they are posted on this page.</p>

      <h2>8. Contact Us</h2>
      <p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at hello@memswipe.app.</p>
    </div>
  );
};

export default PrivacyPolicy;
