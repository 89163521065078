import './Home.css';
import loopVideo from '../../assets/loop.mp4';
import pfp from '../../assets/pfp.png';
import AutoPlaySilentVideo from "../../helpers/video";


function Home() {
  return (
    <div className="Home">
      <div className="video-container">
        <AutoPlaySilentVideo video={loopVideo} className="video-container-src" />
      </div>
      <div className="info-container">
        <div className='title-container'>
          <p className="app-title">MEMSWIPE</p>
          <p className='by'>By</p>
          <a href="https://cui.studio" target='about/blank'>
            <img src={pfp} alt="Profile" className="profile-pic" />
          </a>
        </div>
       
        <p className="app-desc">Memswipe makes organizing your photo library fun and engaging. More importantly, it helps you stumble upon fleeting times and places that you may have forgotten.  </p>
        <a href='https://apps.apple.com/us/app/memswipe/id6478929508' target='_blank' className='download-button'>
          Download
        </a>
      </div>
    </div>
  );
}

export default Home;
